<template>
  <div>
    <div class="text-h4 pt-5 mb-5">Заявки - партнерская программа</div>
    <partners-request-table></partners-request-table>
  </div>
</template>

<script>
import PartnersRequestTable from "@/components/Partners/PartnersRequestTable";

export default {
  name: "PartnersRequest",
  components: { PartnersRequestTable },
};
</script>

<style scoped></style>
