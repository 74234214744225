var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"options":_vm.optionsTable,"server-items-length":1,"loading":_vm.loading,"show-expand":"","footer-props":{ itemsPerPageOptions: [10, 15, 20, 50, 100] }},on:{"update:options":function($event){_vm.optionsTable=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[[_c('v-card',{staticClass:"pa-1"},[_c('v-card-title',{staticClass:"text-h5 text-center justify-center flex-column mb-5"},[_vm._v("Удалить заявку?"),_c('br'),_c('span',{staticClass:"accent--text"},[_vm._v(_vm._s(_vm.itemCurrentName))])]),_c('v-card-actions',{staticClass:"pb-4"},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"accent","outlined":""},on:{"click":_vm.closeDelete}},[_vm._v("Отмена")]),_c('v-btn',{attrs:{"color":"accent"},on:{"click":_vm.deleteItemConfirm}},[_vm._v("OK")]),_c('v-spacer')],1)],1)]],2)]},proxy:true},{key:"item.created_at",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.moment(value).format("YYYY-MM-DD HH:mm"))+" ")]}},{key:"item.firstname",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [_vm._v(" "+_vm._s(value)+" "+_vm._s(item.lastname)+" ")]}},{key:"item.email",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [_c('div',{staticClass:"text-no-wrap py-1"},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-email")]),_vm._v(" "+_vm._s(value)+" ")],1),_c('div',{staticClass:"text-no-wrap py-1"},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-phone")]),_vm._v(" "+_vm._s(item.phone)+" ")],1)]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"py-4",attrs:{"colspan":headers.length}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"8","lg":"6"}},[_vm._v(" "+_vm._s(item.message)+" ")])],1)],1)]}},{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-no-wrap"},[_c('v-icon',{staticClass:"mr-2",on:{"click":function($event){return _vm.updateStatus(item, 'registered')}}},[_vm._v(" mdi-checkbox-outline ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }