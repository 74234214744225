<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="items"
      :options.sync="optionsTable"
      :server-items-length="1"
      :loading="loading"
      show-expand
      :footer-props="{ itemsPerPageOptions: [10, 15, 20, 50, 100] }"
    >
      <template v-slot:top>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <template>
            <v-card class="pa-1">
              <v-card-title
                class="text-h5 text-center justify-center flex-column mb-5"
                >Удалить заявку?<br />
                <span class="accent--text">{{
                  itemCurrentName
                }}</span></v-card-title
              >

              <v-card-actions class="pb-4">
                <v-spacer></v-spacer>
                <v-btn color="accent" outlined @click="closeDelete"
                  >Отмена</v-btn
                >
                <v-btn color="accent" @click="deleteItemConfirm">OK</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </template>
        </v-dialog>
      </template>
      <template v-slot:item.created_at="{ value }">
        {{ moment(value).format("YYYY-MM-DD HH:mm") }}
      </template>
      <template v-slot:item.firstname="{ value, item }">
        {{ value }} {{ item.lastname }}
      </template>
      <template v-slot:item.email="{ value, item }">
        <div class="text-no-wrap py-1">
          <v-icon small>mdi-email</v-icon> {{ value }}
        </div>
        <div class="text-no-wrap py-1">
          <v-icon small>mdi-phone</v-icon> {{ item.phone }}
        </div>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length" class="py-4">
          <v-row>
            <v-col cols="12" md="8" lg="6">
              {{ item.message }}
            </v-col>
          </v-row>
        </td>
      </template>
      <template v-slot:item.id="{ item }">
        <div class="text-no-wrap">
          <v-icon class="mr-2" @click="updateStatus(item, 'registered')">
            mdi-checkbox-outline
          </v-icon>
          <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import api from "../../../api";
import moment from "moment";

export default {
  name: "PartnersRequestTable",
  data() {
    return {
      dialogDelete: false,
      headers: [
        {
          text: "Статус",
          align: "start",
          sortable: false,
          value: "status",
          width: 120,
        },
        {
          text: "Дата",
          align: "start",
          sortable: false,
          value: "created_at",
          width: 120,
        },
        {
          text: "Страна",
          align: "start",
          sortable: false,
          value: "country",
        },
        {
          text: "Город",
          align: "start",
          sortable: false,
          value: "city",
        },
        {
          text: "Имя Фамилия",
          align: "start",
          sortable: false,
          value: "firstname",
        },
        {
          text: "Контакты",
          align: "start",
          sortable: false,
          value: "email",
        },
        {
          text: "Сообщение",
          value: "data-table-expand",
          sortable: false,
          align: "center",
          width: 80,
        },
        {
          text: "",
          value: "id",
          sortable: false,
          align: "end",
          width: 100,
        },
      ],
      items: [],
      editedIndex: -1,
      editedItem: null,
      optionsTable: {},
      total: 0,
      loading: true,
      errors: {},
      referrals: null,
      invites: null,
      updateInterval: null,
    };
  },

  computed: {
    itemCurrentName() {
      return this.editedIndex > -1 ? this.items[this.editedIndex].lastname : "";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    optionsTable: {
      handler() {
        this.getItems();
      },
      deep: true,
    },
  },
  created() {
    this.updateInterval = setInterval(() => this.getItems(), 1000 * 60 * 5);
  },
  beforeDestroy() {
    this.updateInterval = clearInterval(this.updateInterval);
  },
  methods: {
    deleteItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      await this.removeItem(this.items[this.editedIndex].id);
      this.getItems();
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = null;
        this.editedIndex = -1;
        this.errors = {};
        this.resetErrors(this.editedItem, "editedItem");
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = null;
        this.editedIndex = -1;
      });
    },

    async getItems() {
      this.loading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.optionsTable;
      try {
        const req = await api.partners.affiliatesApplications({
          sortBy: sortBy && sortBy.length > 0 ? sortBy[0] : null,
          sortDesc: sortDesc && sortDesc.length > 0 ? sortDesc[0] : null,
          page: page,
          itemsPerPage: itemsPerPage,
        });
        if (req.data) {
          this.items = req.data.items;
          this.total = req.data.total;
        }
        this.loading = false;
      } catch (e) {
        console.error(e);
        this.loading = false;
      }
    },

    async removeItem(id) {
      try {
        await api.partners.affiliatesApplicationsDelete(id);
        await this.$store.dispatch("getAffiliatesApplicationsCount");
        this.$store.dispatch("showSnackbar", {
          msg: `Заявка удалена`,
          color: "success",
          show: true,
        });
      } catch (e) {
        console.error(e);
        this.$store.dispatch("showSnackbar", {
          msg: "Ошибка удаления",
          color: "error",
          show: true,
        });
      }
    },

    async updateStatus(item, status) {
      switch (item.status) {
        case "new":
          try {
            const index = this.items.indexOf(item);
            await api.partners.affiliatesApplicationsUpdate(item.id, {
              ...item,
              status,
            });
            await this.$store.dispatch("getAffiliatesApplicationsCount");
            this.$set(this.items[index], "status", status);
          } catch (e) {
            console.error(e);
          }
          break;
        default:
          return;
      }
    },
    moment(date) {
      return moment(date);
    },
  },
};
</script>

<style lang="scss" scoped></style>
